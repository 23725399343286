import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: useRuntimeConfig()?.public?.sentry?.dsn,
  environment: process.env.NODE_ENV,
  integrations: [
    // Add browser profiling integration to the list of integrations
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.httpClientIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  tracePropagationTargets: ["sw6.sawade.berlin"],
});
